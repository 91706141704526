.contentRegister{
    display: flex;
    justify-content: center;
    background-image: url('../assets/img/bg_login$register.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    height: 100%;
}
.boxRegister{
    margin: 5rem 0;
    max-width: 600px;
    padding: calc(1rem + 1vw);
    border-radius: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #fff;
    height: 100%;
}
.contentCondition{
    display: flex;
    align-items: center;
}
.titleCondition{
    font-size: calc(11px + 0.25vw);
    margin-left: calc(0.5rem + 00.5vw);
    font-weight: bold;
}
.condition{
    font-weight: bold;
    color: #1F2BA6;
    border-bottom: 1px solid #1F2BA6;
}
.goBack{
    font-size: 15px;
    text-decoration: none;
    color: #1F2BA6;
    font-weight: bold;
    margin-bottom: 2rem;
}
