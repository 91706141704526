/* @font-face {
  font-family: 'Kanit';
  src: url('./assets/fonts/Kanit-Regular.ttf');
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Kanit' !important;
} */

@font-face {
  font-family: 'IBM Plex Sans Thai Looped';
  src: url('./assets/fonts/IBMPlexSansThai-Regular.ttf');
}

* {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Sans Thai Looped' !important;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent
}
::-webkit-scrollbar {
  height: 0.65rem;
  width: 0.65rem;
}
::-webkit-scrollbar-thumb{
  background-color: #1F2BA6;
  border-radius: 0.25rem;
}
.bg__rama__dashboard {
  background-color: #FBFBFB;
  min-height: 100vh;
  height: auto;
}


#map {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@keyframes fade { 
  from { opacity: 0.2; } 
}

.blinking {
  animation: fade 0.3s infinite alternate;
}