.btGreen {
    background-color: #00D0FF17;
    border: 1px solid #1F2BA6;
    border-radius: 10px;

    color: #1F2BA6;
    text-align: center;
    padding: 10px;
}

.btGay {
    background-color: none;
    border: none;
    border-radius: 10px;

    color: #747676;
    text-align: center;
    padding: 10px;
}

.btGay , .btGreen:hover {
    cursor: pointer;
}

.date {
    font-weight: 600;
    font-size: 24px;
    color: #1F2BA6;
}

.title {
    font-weight: 600;
    font-size: 20px;
    color: #222222;
}

.key {
    font-weight: 600;
    font-size: 18px;
    color: #1F2BA6;
}

.value {
    margin-left: 20px;
    font-size: 16px;
    color: #1F2BA6;
}

.value2 {
    font-size: 16px;
    color: #747676;
}

.value3 {
    margin-left: 20px;
    font-size: 16px;
    color: #747676;
}

.detail {
    display: flex;
    grid-gap: 10px;
    margin: 10px 0;
}

.titleBar {
    font-weight: 500;
    font-size: 18px;
}

.alignBar {
    display: flex;
    text-align: center;
    font-size: 16px;
}

.widthBar {
    width: 100px;
    height: 12px;
}

.Img {
    width: 400px;
}

.imgProfile {
    width: 80px;
    border-radius: 50%;
}

@media screen and (max-width: 800px) {
    .date {
        font-size: 18px;
    }
    
    .title {
        font-size: 16px;
    }
    
    .key {
        font-size: 14px;
    }
    
    .value {
        font-size: 14px;
    }
    
    .value2 {
        font-size: 14px;
    }

    .value3 {
        font-size: 14px;
    }

    .titleBar {
        font-size: 12px;
    }
    
    .alignBar {
        font-size: 10px;
    }
    
    .widthBar {
        width: 60px;
        height: 8px;
    }

    .Img {
        width: 300px;
    }

    .imgProfile {
        width: 60px;
    }
}