.btGreen {
    background-color: #00D0FF17;
    border: 1px solid #1F2BA6;
    border-radius: 10px;

    color: #1F2BA6;
    text-align: center;
    padding: 10px;
}

.btGay {
    background-color: none;
    border: none;
    border-radius: 10px;

    color: #747676;
    text-align: center;
    padding: 10px;
}

.history {
    background-color: #00D0FF17;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}